import "./PartnerScroll.scss";
// import AOS
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

// photos import
import FinisgHug from "../../Resources/Pics/fulfillments/fulfillment-finish-hug.webp";
import Powrent from "../../Resources/Pics/fulfillments/fulfillment-pouwrent.webp";
import Ansys from "../../Resources/Pics/fulfillments/fulfillment-ansys.webp";
import Greatwaves from "../../Resources/Pics/fulfillments/fulfillment-greatwaves.webp";
import Indi from "../../Resources/Pics/fulfillments/fulfillment-indi.webp";
import Oldenburger from "../../Resources/Pics/fulfillments/fulfillment-oldenburger.webp";
import PartnerFlag from "../../Resources/Pics/fulfillments/fulfillment-partner-flag.webp";
import Boikon from "../../Resources/Pics/fulfillments/fulfillment-boikon.webp";
import Canon from "../../Resources/Pics/fulfillments/fulfillment-canon.webp";
import D3Suspension from "../../Resources/Pics/fulfillments/fulfillment-d3suspension.webp";
import Eurocircuits from "../../Resources/Pics/fulfillments/fulfillment-eurocircuits.webp";
import EventPresentation from "../../Resources/Pics/fulfillments/fulfillment-event-presentation.webp";
import Wagenborg from "../../Resources/Pics/fulfillments/fulfillment-wagenborg.webp";
import BoschTool from "../../Resources/Pics/fulfillments/fulfillment-bosch-tool.webp";
import Bam from "../../Resources/Pics/fulfillments/fulfillment-bam.webp";
import Flir from "../../Resources/Pics/fulfillments/fulfillment-flir.webp";
import Igus from "../../Resources/Pics/fulfillments/fulfillment-igus.webp";
import BoschPorto from "../../Resources/Pics/fulfillments/fulfillment-bosch-porto.webp";
import Omnium from "../../Resources/Pics/fulfillments/fulfillment-omnium.webp";
import QRcode from "../../Resources/Pics/fulfillments/fulfillment-qr-code.webp";

function PartnerScroll() {

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 700);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fulfillments = [
    FinisgHug, Powrent, Ansys, Greatwaves, Indi, Oldenburger, PartnerFlag, Boikon, Canon, D3Suspension, Eurocircuits, EventPresentation, Wagenborg, BoschTool, Bam, Flir, Igus, BoschPorto, Omnium, QRcode,
    FinisgHug, Powrent, Ansys, Greatwaves, Indi, Oldenburger, PartnerFlag, Boikon, Canon, D3Suspension, Eurocircuits, EventPresentation, Wagenborg, BoschTool, Bam, Flir, Igus, BoschPorto, Omnium, QRcode
  ];

  const fulfillmentsList = fulfillments.map((fulfillment, index) => {
    return (
      <div className="slide" key={fulfillment}>
        <img
         src={fulfillment}
         alt="fulfillment"
         className="fulfillment"
         width={isSmallScreen ? "300rem" : "570rem"}
         loading="eager"
         effect="blur"
        />
      </div>
    );
  });
  return (
    <>
      <div className="container-fluid team overflow-hidden">
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-12 slider">
            <div className="slide-track">
              {fulfillmentsList}
            </div>
          </div>
          <Link to="/partners">
            <span className="title">
              <div className="gray-box">
                <h1 data-aos="fade-right" data-aos-duration="1000">
                  Driven&nbsp;
                </h1>
                <h1 data-aos="fade-left" data-aos-duration="1000">
                  <text className="emphasis">  /Together</text>
                </h1>
              </div>

            </span>{" "}
          </Link>
        </div>

        

      </div>
    </>
  );
}

export default PartnerScroll;
