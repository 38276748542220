import Aos from "aos";
import "./Cars.scss";
import green_spirit from "../../Resources/Pics/cars/GreenSpirit.webp";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faWeightHanging,
  faRulerCombined,
  faTachometerAlt,
  faSolarPanel,
  faBolt,
  faCubes,
  faPercentage,
} from "@fortawesome/free-solid-svg-icons";

function GreenSpirit() {
  useEffect(() => {
    Aos.init();
  }, []);

  const [flippedIndex, setFlippedIndex] = useState(null);

  const handleFlip = (index) => {
    setFlippedIndex(index === flippedIndex ? null : index);
  };
  const details = [
    {
      icon: faCalendarAlt,
      label: "Year of Construction",
      value: "2021",
      explanation:
        "The build started in August 2020 and was finalized in September 2021.",
    },
    {
      icon: faWeightHanging,
      label: "Weight",
      value: "200 kg",
      explanation:
        "The car was made as light as possible to be as efficient as possible.",
    },
    {
      icon: faRulerCombined,
      label: "Dimensions",
      value: "1.3m x 1.1m x 5m",
      explanation:
        "The dimensions are optimized for both aerodynamics and stability.",
    },
    {
      icon: faTachometerAlt,
      label: "Top Speed",
      value: "110 km/h",
      explanation: "Top speed achieved under ideal conditions.",
    },
    {
      icon: faCubes,
      label: "Solar Cells Type",
      value:
        "Silicon Heterojuction Cells with Smart Wire Connection Technology",
      explanation:
        "Silicon Heterojuction Cells with Smart Wire Connection Technology",
    },
    {
      icon: faSolarPanel,
      label: "Solar Area",
      value: "4 m²",
      explanation: "Solar panels maximize energy capture.",
    },
    {
      icon: faBolt,
      label: "Motor Power",
      value: "1kW nominal \n 10kW peak",
      explanation: "Motor provides high peak power for acceleration.",
    },
    {
      icon: faPercentage,
      label: "Motor Efficiency",
      value: "97.8%",
      explanation: "Highly efficient motor to reduce energy loss.",
    },
  ];
  return (
    <div>
      <div
        className="special container simple-section mb-5"
        style={{
          backgroundImage: `url(${green_spirit})`,
          backgroundSize: `cover`,
          backgroundPosition: `center`,
        }}
      >
        <div className="row simple-section-header">
          <h1
            style={{
              marginTop: `10rem`,
              marginBottom: `10rem`,
              color: `white`,
            }}
          >
            Green Spirit
          </h1>
        </div>
      </div>
      <div className="special container simple-section mt-5 mb-5">
        <div className="row simple-section-header">
          <h1>
            The <text className="emphasis"> /Specifications </text>
          </h1>
        </div>

        <div className="row simple-section-body">
          <div className="info-grid">
            {details.map((item, index) => (
              <div
                key={index}
                className={`info-item ${
                  flippedIndex === index ? "flipped" : ""
                }`}
                onClick={() => handleFlip(index)}
              >
                {/* Front of the card */}
                <div className="card-front">
                  {/* <FontAwesomeIcon icon={item.icon} size="2x" /> */}
                  <h3>{item.label}</h3>
                  <p style={{ whiteSpace: "pre-line" }}>{item.value}</p>
                </div>

                {/* Back of the card */}
                <div className="card-back">
                  <p>{item.explanation}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="container simple-section mb-5">
        <div className="row simple-section-header">
          <h1>
            The <text className="emphasis"> /Story </text>
          </h1>
        </div>
        <div className="row simple-section-body">
          <p>
            With the Bridgestone World Solar Challenge 2021 being cancelled,
            there was an uncertainty whether or not to build a solar car.
            Luckily, a large group of teams put their heads together and managed
            to organize the Moroccan Solar Challenge 2021!
          </p>
          <p>
            Racing in this challenge was our beloved Green Spirit, the second
            Top Dutch solar car. It is a 4-wheeled mono-hull solar car,
            hand-build from scratch with carbon and glass fibre. With an 100%
            self-made electrical system and a nearly 100% self developed
            mechanical system, we put all our knowledge and skills into this
            car.
          </p>
        </div>
      </div>
    </div>
  );
}

export default GreenSpirit;
