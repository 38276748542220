import Aos from "aos";
import "./Cars.scss";
import greenThunder from "../../Resources/Pics/cars/GreenThunder.webp";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faWeightHanging,
  faRulerCombined,
  faTachometerAlt,
  faSolarPanel,
  faBolt,
  faCubes,
  faPercentage,
} from "@fortawesome/free-solid-svg-icons";

function GreenThunder() {
  useEffect(() => {
    Aos.init();
  }, []);

  const [flippedIndex, setFlippedIndex] = useState(null);

  const handleFlip = (index) => {
    setFlippedIndex(index === flippedIndex ? null : index);
  };

  const details = [
    {
      icon: faCalendarAlt,
      label: "Year of Construction",
      value: "2023",
      explanation:
        "The build started in August 2022 and was finalized in September 2023.",
    },
    {
      icon: faWeightHanging,
      label: "Weight",
      value: "160 kg",
      explanation:
        "The car was made as light as possible to be as efficient as possible.",
    },
    {
      icon: faRulerCombined,
      label: "Dimensions",
      value: "1.26m x 1m x 5m",
      explanation:
        "The dimensions are optimized for both aerodynamics and stability.",
    },
    {
      icon: faTachometerAlt,
      label: "Top Speed",
      value: "130 km/h",
      explanation: "Top speed achieved under ideal conditions.",
    },
    {
      icon: faCubes,
      label: "Solar Cells Type",
      value: "Tandem silicon-perovskite",
      explanation: "",
    },
    {
      icon: faSolarPanel,
      label: "Solar Area",
      value: "4 m²",
      explanation: "Solar panels maximize energy capture.",
    },
    {
      icon: faBolt,
      label: "Motor Power",
      value: "1kW nominal \n 15kW peak",
      explanation: "Motor provides high peak power for acceleration.",
    },
    {
      icon: faPercentage,
      label: "Motor Efficiency",
      value: "97.5%",
      explanation: "Highly efficient motor to reduce energy loss.",
    },
  ];
  return (
    <div>
      <div
        className="special container simple-section mb-5"
        style={{
          backgroundImage: `url(${greenThunder})`,
          backgroundSize: `cover`,
          backgroundPosition: `center`,
        }}
      >
        <div className="row simple-section-header">
          <h1
            style={{
              marginTop: `10rem`,
              marginBottom: `10rem`,
              color: `white`,
            }}
          >
            Green <text className="emphasis"> Thunder </text>
          </h1>
        </div>
      </div>
      <div className="special container simple-section mt-5 mb-5">
        <div className="row simple-section-header">
          <h1>
            The <text className="emphasis"> /Specifications </text>
          </h1>
        </div>

        <div className="row simple-section-body">
          <div className="info-grid">
            {details.map((item, index) => (
              <div
                key={index}
                className={`info-item ${
                  flippedIndex === index ? "flipped" : ""
                }`}
                onClick={() => handleFlip(index)}
              >
                {/* Front of the card */}
                <div className="card-front">
                  {/* <FontAwesomeIcon icon={item.icon} size="2x" /> */}
                  <h3>{item.label}</h3>
                  <p style={{ whiteSpace: "pre-line" }}>{item.value}</p>
                </div>
                {/* Back of the card */}
                <div className="card-back">
                  <p>{item.explanation}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="container simple-section mb-5">
        <div className="row simple-section-header">
          <h1>
            The <text className="emphasis"> /Story </text>
          </h1>
        </div>
        <div className="row simple-section-body">
          <p>
            Green Thunder is the newest solar-powered racing car developed by
            the Top Dutch Solar Racing team, a student-led initiative from
            Groningen, the Netherlands. It is the third iteration of their
            innovative solar cars, following the successful Green Lightning and
            Green Spirit models. Green Thunder is designed with a singular goal
            in mind - to claim victory at the prestigious Bridgestone World
            Solar Challenge. This latest model encapsulates the lessons learned
            from its predecessors while innovating on their strengths. The car
            incorporates the speed and efficiency demonstrated by Green
            Lightning, which managed to secure a pole position start and a
            fourth-place finish at the team's debut in the 2019 Bridgestone
            World Solar Challenge. Furthermore, it builds on the resilience and
            reliability shown by Green Spirit, the team's second solar car that
            was revealed in 2021.
          </p>
          <p>
            Green Thunder represents the team's continuous commitment to
            sustainable innovations, technology, and engineering excellence.
            It's a testament to the students' relentless drive to push
            boundaries in solar car technology. The car showcases the team's
            evolution in design, performance, and energy efficiency, setting a
            new benchmark for solar-powered vehicles. The Top Dutch Solar Racing
            team is optimistic that Green Thunder has what it takes to emerge
            victorious in the upcoming Bridgestone World Solar Challenge.
          </p>
        </div>
      </div>
    </div>
  );
}

export default GreenThunder;
