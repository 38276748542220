import Aos from "aos";
import "./Cars.scss";
import green_lightning from "../../Resources/Pics/cars/GreenLightning.webp";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faWeightHanging,
  faRulerCombined,
  faTachometerAlt,
  faSolarPanel,
  faBolt,
  faRadiation,
  faPercentage,
} from "@fortawesome/free-solid-svg-icons";

function GreenLightning() {
  useEffect(() => {
    Aos.init();
  }, []);

  const [flippedIndex, setFlippedIndex] = useState(null);

  const handleFlip = (index) => {
    setFlippedIndex(index === flippedIndex ? null : index);
  };
  const details = [
    {
      icon: faCalendarAlt,
      label: "Year of Construction",
      value: "2019",
      explanation:
        "Top Dutch was founded in 2017 and after 2 years Green Lightning was done.",
    },
    {
      icon: faWeightHanging,
      label: "Weight",
      value: "150 kg",
      explanation:
        "The car was made as light as possible to be as efficient as possible.",
    },
    {
      icon: faRulerCombined,
      label: "Dimensions",
      value: "1.2m x 1m x 5m",
      explanation:
        "The dimensions are optimized for both aerodynamics and stability.",
    },
    {
      icon: faTachometerAlt,
      label: "Top Speed",
      value: "140 km/h",
      explanation: "Top speed achieved under ideal conditions.",
    },
    {
      icon: faRadiation,
      label: "Solar Cells Type",
      value: "Single junction GaAs",
      explanation: "Highly efficient GaAs cells to maximize energy gain.",
    },
    {
      icon: faSolarPanel,
      label: "Solar Area",
      value: "3.56 m²",
      explanation: "Solar panels maximize energy capture.",
    },
    {
      icon: faBolt,
      label: "Motor Power",
      value: "2 kW",
      explanation: "Motor provides high peak power for acceleration.",
    },
    {
      icon: faPercentage,
      label: "Motor Efficiency",
      value: "96%",
      explanation:
        "First of a kind electro motor. Design to be as efficient as possible.",
    },
  ];
  return (
    <div>
      <div
        className="special container simple-section mb-5"
        style={{
          backgroundImage: `url(${green_lightning})`,
          backgroundSize: `cover`,
          backgroundPosition: `center`,
        }}
      >
        <div className="row simple-section-header">
          <h1
            style={{
              marginTop: `10rem`,
              marginBottom: `10rem`,
              color: `white`,
            }}
          >
            Green <text className="emphasis"> Lightning </text>
          </h1>
        </div>
      </div>
      <div className="special container simple-section mt-5 mb-5">
        <div className="row simple-section-header">
          <h1>
            The <text className="emphasis"> /Specifications </text>
          </h1>
        </div>

        <div className="row simple-section-body">
          <div className="info-grid">
            {details.map((item, index) => (
              <div
                key={index}
                className={`info-item ${
                  flippedIndex === index ? "flipped" : ""
                }`}
                onClick={() => handleFlip(index)}
              >
                {/* Front of the card */}
                <div className="card-front">
                  {/* <FontAwesomeIcon icon={item.icon} size="2x" /> */}
                  <h3>{item.label}</h3>
                  <p style={{ whiteSpace: "pre-line" }}>{item.value}</p>
                </div>

                {/* Back of the card */}
                <div className="card-back">
                  <p>{item.explanation}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="container simple-section mb-5">
        <div className="row simple-section-header">
          <h1>
            The <text className="emphasis"> /Story </text>
          </h1>
        </div>
        <div className="row simple-section-body">
          <p>
            Green Lightning was the first Top Dutch solar car and was the result
            of two years of engineering, from 2017 until 2019. The light,
            mono-hulled, carbon body has enough space for innovative parts that
            have been built from scratch.
          </p>
          <p>
            Two examples of these custom parts are the four wheel steering
            system and composite leaf springs. By using the four wheel steering
            system, the solar car can drive at an angle, which reduces
            aerodynamical resistance. The composite leaf springs contribute to
            the stability of the vehicle.
          </p>
        </div>
      </div>
    </div>
  );
}

export default GreenLightning;
