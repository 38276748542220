import PartnerScroll from "./partnerScroll"
import "../../custom.scss"
import "./Partners.scss";

import seatrade from "../../Resources/Pics/partners/seatrade-main-white.png";
// Main partners
import rug from "../../Resources/Pics/partners/rug-large.svg";
import hanze from "../../Resources/Pics/partners/new-hanze-logo.svg";
import noord from "../../Resources/Pics/partners/noord-large.svg";
import alfa from "../../Resources/Pics/partners/alfa-large.svg";
import ROC from "../../Resources/Pics/partners/ROC-large.svg";
import firda from "../../Resources/Pics/partners/firda-knowledge.svg";
// green partners
import provGron from "../../Resources/Pics/partners/provincie-groningen-green.svg";
import vanGroningen from "../../Resources/Pics/partners/vangroningen-green.svg";
import oxfordPV from "../../Resources/Pics/partners/oxford-green.svg";
// platinum partners
import viro from "../../Resources/Pics/partners/viro-green.svg";
import loxam from "../../Resources/Pics/partners/loxam-plat.svg";
import greatwaves from "../../Resources/Pics/partners/greatwaves-plat.svg";
// import ansys from "../../Resources/Pics/partners/ansys-plat.svg";
import oldernburg from "../../Resources/Pics/partners/oldernburg-plat.svg";
// gold partners
import indi from "../../Resources/Pics/partners/indi-gold.svg";
import nedcam from "../../Resources/Pics/partners/nedcam-gold.svg";
import RDW from "../../Resources/Pics/partners/RDW-gold.svg";
import vanmossel from "../../Resources/Pics/partners/vanmossel-gold.svg";
import fokker from "../../Resources/Pics/partners/fokker-gold.svg";
import sony from "../../Resources/Pics/partners/sony-gold.svg";
import assen from "../../Resources/Pics/partners/assen-gold.svg";
import pouw from "../../Resources/Pics/partners/pouw-gold.svg";
import rabobank from "../../Resources/Pics/partners/rabobank-gold.svg";
import weather from "../../Resources/Pics/partners/weather-gold.svg";
// silver partners
import infinite from "../../Resources/Pics/partners/infinite-silver.svg";
import salomon from "../../Resources/Pics/partners/salomon-silver.svg";
import kvaser from "../../Resources/Pics/partners/kvaser-silver.svg";
import eekels from "../../Resources/Pics/partners/eekels-silver.svg";
import simba from "../../Resources/Pics/partners/simba-silver.svg";
import miontronics from "../../Resources/Pics/partners/miontronics-silver.svg";
import eurocircuits from "../../Resources/Pics/partners/eurocircuits-silver.svg";
import k3d from "../../Resources/Pics/partners/k3d-silver.svg";
import TPEE from "../../Resources/Pics/partners/TPEE-silver.svg";
import raytek from "../../Resources/Pics/partners/raytek-silver.svg";
import rood from "../../Resources/Pics/partners/rood-silver.svg";
import mabutex from "../../Resources/Pics/partners/mabutex-silver.svg";
// bronze partners
import boikon from "../../Resources/Pics/partners/boikon-bronze.svg";
import easy_composites from "../../Resources/Pics/partners/easy-composites-bronze.svg";
import defensity from "../../Resources/Pics/partners/defensity-bronze.svg";
import dutchbeans from "../../Resources/Pics/partners/dutchbeans-bronze.svg";
import trip from "../../Resources/Pics/partners/trip-bronze.svg";
import wurth from "../../Resources/Pics/partners/wurth-bronze-new.svg";
import altium from "../../Resources/Pics/partners/altium-bronze.svg";
import emkay from "../../Resources/Pics/partners/emkay-bronze.svg";
import prohelion from "../../Resources/Pics/partners/prohelion-bronze.svg";
import d3 from "../../Resources/Pics/partners/d3-bronze.svg";
import hexagon from "../../Resources/Pics/partners/hexagon-bronze.svg";
import meilink from "../../Resources/Pics/partners/meilink-bronze.svg";
import igus from "../../Resources/Pics/partners/igus-bronze.svg";
import elcee from "../../Resources/Pics/partners/elcee-bronze.svg";
import vdl from "../../Resources/Pics/partners/vdl-bronze.svg";
import roboflex from "../../Resources/Pics/partners/roboflex-bronze.svg";
// import hydroflex from "../../Resources/Pics/partners/hydroflex-bronze.svg";
// import adruu from "../../Resources/Pics/partners/adruu-bronze.svg";
import bridgestone from "../../Resources/Pics/partners/bridgestone-bronze.svgz";
import stuwkrach from "../../Resources/Pics/partners/stuwkrach-broze.svg";
import unive from "../../Resources/Pics/partners/unive-bronze.svg";
import fluctus from "../../Resources/Pics/partners/fluctus-bronze.svg";
import makerspace from "../../Resources/Pics/partners/makerspace-bronze.svg";
import tkp from "../../Resources/Pics/partners/tkp-bronze.svg";
import repos from "../../Resources/Pics/partners/repos-bronze.svg";
import bionic from "../../Resources/Pics/partners/bionic-bronze.svg";
import saba from "../../Resources/Pics/partners/saba-bronze.svg";
import pontifex from "../../Resources/Pics/partners/pontifex-bronze.svg";
import target from "../../Resources/Pics/partners/target-bronze.svg";
import canon from "../../Resources/Pics/partners/canon-bronze.svg";
// import hydro from "../../Resources/Pics/partners/hydro-bronze.svg";
import omnyacc from "../../Resources/Pics/partners/omnyacc-bronze.svg";
import stm from "../../Resources/Pics/partners/STM.svg";
import PartnersInfo from "./Partners-info";
import cablemasters from "../../Resources/Pics/partners/cablemasters-gold.svg";
import de_boer from "../../Resources/Pics/partners/de-boer-bouwmanagement-bronze-duotone1.svg"; 
import sportconfex from "../../Resources/Pics/partners/sportconfex.svg";


export default function JoinUs(){
    console.log(CSS.supports("animation-timeline", "scroll()"));

    return(
        <div>
            <PartnerScroll/>
            <div className="container extra-space simple-section">
                <div className="row simple-section-header">
                    <h1>Word jij onze nieuwe partner?</h1>
                </div>
                <div className="row simple-section-body">

                    <p>
                    Word ook partner bij Top Dutch Solar Racing en maak deel uit van een beweging die de toekomst van duurzame mobiliteit en hernieuwbare 
                    energie vormgeeft! Door met ons samen te werken krijgt jouw merk de unieke kans om internationaal door te breken, bijvoorbeeld tijdens 
                    onze iconische 3000 kilometer race door de ruige Australische Outback. Dit is jouw kans om je inzet voor duurzaamheid te bewijzen en 
                    tegelijkertijd een wereldwijd publiek te bereiken in Nederland, Australië en ver daarbuiten!
                    </p>

                    <p>
                    Als partner werk je samen met cutting-edge technologie en innovatie. Je werkt direct met onze ambitieuze studenten en met grote namen 
                    uit de industrie die de toekomst van innovatie bepalen. Daarnaast inspireer je toekomstige generaties om grote stappen te zetten richting 
                    een groenere toekomst. Met op maat gemaakte partnershippakketten, en toegang tot een groot en dynamisch netwerk van innovators, bedrijven 
                    en professionals, krijg je de kans om een echte impact te maken op de toekomst van hernieuwbare energie.
                    </p>

                    <p>
                    Sluit je aan en zet vandaag de eerste stap naar een groener morgen! We are driven to get there, together!
                    </p>
                </div>
            </div>

            <div className="title-container extra-space">
                
                <div className="image green " style={{left: "24vw", top: "7vh"}}>
                    <img src={rug} alt="Icon 2" />
                </div>
                   
                    <h1 className="title text-move" onClick={() => window.location.href = "mailto:acquisition@solarracinggroningen.nl"} >
                        <text className="title" href="mailto:acquisition@solarracinggroningen.nl"> /sluit je aan</text>
                    </h1>
                    
                <div className="image green" style={{left: "65vw", top: "5vh"}}>
                    <img src={hanze} alt="Icon 3" />
                </div>
                <div className="image green" style={{left: "28vw", top: "27vh"}}>
                    <img src={provGron} alt="Icon 4" />
                </div>
                <div className="image green" style={{left: "55vw", top: "30vh"}}>
                    <img src={vanGroningen} alt="Icon 4" />
                </div>

                <div className="image knowledge" style={{left: "15vw", top: "3vh"}}>
                    <img src={alfa} alt="Icon 1" />
                </div>
                <div className="image knowledge" style={{left: "42vw", top: "42vh", filter: "invert(0)"}}>	
                    <img src={firda} alt="Icon 1" />
                </div>
                <div className="image knowledge" style={{left: "86vw", top: "10vh"}}>
                    <img src={noord} alt="Icon 1" />
                </div>

                <div className="image plat" style={{left: "45vw", top: "6vh"}}>
                    <img src={oldernburg} alt="Icon 1" />
                </div>
                <div className="image plat" style={{left: "75vw", top: "24vh"}}>
                    <img src={seatrade} alt="Icon 1" />
                </div>
                <div className="image plat" style={{left: "13vw", top: "25vh"}}>
                    <img src={fokker} alt="Icon 1" />
                </div>
                <div className="image plat" style={{left: "70vw", top: "0"}}>
                    <img src={greatwaves} alt="Icon 1" />
                </div>

                <div className="image gold" style={{left: "85vw", top: "40vh"}}>
                    <img src={bionic} alt="Icon 1" />
                </div>
                <div className="image gold" style={{left: "2vw", top: "25vh"}}>
                    <img src={RDW} alt="Icon 1" />
                </div>
                <div className="image gold" style={{left: "90vw", top: "2vh"}}>
                    <img src={sony} alt="Icon 1" />
                </div>
                <div className="image gold" style={{left: "35vw", top: "0vh"}}>
                    <img src={assen} alt="Icon 1" />
                </div>
                <div className="image gold" style={{left: "20vw", top: "45vh"}}>
                    <img src={cablemasters} alt="Icon 1" />
                </div>
                <div className="image gold" style={{left: "4vw", top: "1vh"}}>
                    <img src={pouw} alt="Icon 1" />
                </div>
                <div className="image gold" style={{left: "70vw", top: "46vh"}}>
                    <img src={rabobank} alt="Icon 1" />
                </div>

            </div>
            
        </div>
    )
}