import "./Partners.scss";

import { useRef, useEffect, useState } from "react";

function PartnersInfo() {
  const YoutubeVideo = ({ videoId }) => {
    const videoUrl = `https://www.youtube.com/embed/${videoId}?autoplay=0&loop=1&controls=0&mute=1&disablekb=1&playlist=${videoId}`;

    return (
      <div>
        <iframe
          width="100%"
          height="220"
          src={videoUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="autoplay; loop; fullscreen"
          allowFullScreen
        ></iframe>
      </div>
    );
  };

  return (
    <>
      <div className="container simple-section">
        <div className="row simple-section-header">
          <h1>The Partners Behind Our Success</h1>
        </div>
        <div className="row simple-section-body">
          <p>
          Top Dutch Solar Racing's achievements would not have been possible without the invaluable support of our partners. 
          From industry-leading technology companies to local businesses and educational institutions, 
          each partner has contributed expertise, resources, 
          and innovation to push our solar car to new heights. Their commitment to sustainable mobility, 
          engineering excellence, and knowledge-sharing has empowered our team to design, build, and race a world-class solar vehicle.
          </p>
        </div>
        {/* <div className="row simple-section-body">
          <div className="col-md-4 ">
            <YoutubeVideo videoId="A1zilq_5Efo" />
          </div>

          <div className="col-md-4 ">
            <YoutubeVideo videoId="z7UTankt1hY" />{" "}
          </div>
          <div className="col-md-4">
            <YoutubeVideo videoId="Vklkq7tvxE8" />{" "}
          </div>
        </div> */}
      </div>
    </>
  );
}

export default PartnersInfo;
