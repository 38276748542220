import "./language.scss";
import "../custom.scss";
import TDSR from "../Resources/Pics/Full colour white - TDSR logo.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Aupic from "./../Resources/Pics/flags/Flag_of_Australia.png";
import NLpic from "./../Resources/Pics/flags/Flag_of_the_Netherlands.webp";
export default function Lang() {
    const navigate = useNavigate();
    
      const handleScrollToSection = (hash) => {
        navigate(`/${hash}`);
        document.querySelector(hash).scrollIntoView({ behavior: "smooth", block: "center" });
      };
    return (
          <div className="backgroundLang">
            <img src={TDSR} width="400" alt="Top Dutch Solar Racing" />
            <h1>Kies een taal / Choose a language</h1>
            <div className="flags">
              <p className="flag"  onClick={() => handleScrollToSection('join-us')}>
                <img className="img" src={Aupic} alt="" />
                <h1 className="center">English</h1>
              </p>
              <p className="flag" onClick={() => handleScrollToSection('join-us-nl')} >
                <img className="img" src={NLpic} alt="" />
                <h1>Nederlands</h1>
              </p>
            </div>
          </div>
      );
  }