// imports

import "./Footer.scss";

import "react-lazy-load-image-component/src/effects/blur.css";
import "aos/dist/aos.css";
import { HashLink } from "react-router-hash-link";
import PrivacyPolicy from "../../Pages/PrivacyPolicy/PrivacyPolicy";
import software_department from "../../Resources/Pics/software_department.png";
// constants
let linkedIn = "https://www.linkedin.com/company/solarracing/mycompany/";
let instagram = "https://www.instagram.com/topdutchsolarracing/";
let facebook = "https://www.facebook.com/topdutchsolarracing";
let x = "https://x.com/tdsolarracing";
let youtube = "https://www.youtube.com/channel/UCujnrOD35tMp0dCDoJp5qUw";
let newTab = "_blank";
let tiktok = "https://www.tiktok.com/@topdutchsolarracing";
let bestuur = "bestuur@solarracinggroningen.nl";
let acquisition = "acquisition@solarracinggroningen.nl";
let comms = "pers@solarracinggroningen.nl";

const Footer = () => {
  return (
    <footer className="footer-general container-fluid footer">
      <div className="footer-options ">
        <div className="footer-options-icons row justify-content-around">
          <div className="footer-contact col-md-4  d-flex flex-column align-content-around text-center">
            <p>Contact Us</p>
            <div className="footer-contact-special">
              <a
                className=" mail"
                href="mailto:bestuur@solarracinggroningen.nl"
              >
                <text className="emphasis"> /Bestuur</text> {bestuur}
              </a>

              <br></br>
              <a className=" mail" href="mailto:pers@solarracinggroningen.nl">
                {" "}
                <text className="emphasis"> /Communication</text> {comms}
              </a>

              <br></br>
              <a
                className="mail"
                href="mailto:acquisition@solarracinggroningen.nl"
              >
                <text className="emphasis"> /Acquisition</text> {acquisition}
              </a>
            </div>
          </div>
          <div className="footer-contact col-md-4  d-flex flex-column align-content-around text-center">
            <p>Address</p>
            <div className="footer-contact-special">
              <a className=" mail" href="https://goo.gl/maps/FDftEgUEMXhbBMcz9">
                <text className="emphasis"> /Top Dutch Solar Racing</text>{" "}
                Zernikelaan 17, 9747 AA Groningen
              </a>
              <br></br>
            </div>
          </div>
          <div className="footer-social col-md-4 d-flex flex-column align-content-around text-center">
            <p>Social Media</p>
            <div>
              <a
                href={facebook}
                class="fa-brands fa-facebook"
                target={newTab}
              ></a>
              <a
                href={instagram}
                class="fa-brands fa-instagram"
                target={newTab}
              ></a>
              <a href={x} class="fa-brands fa-x-twitter" target={newTab}></a>
              <a
                href={linkedIn}
                class="fa-brands fa-linkedin"
                target={newTab}
              ></a>
              <a
                href={youtube}
                class="fa-brands fa-youtube"
                target={newTab}
              ></a>
              <a href={tiktok} class="fa-brands fa-tiktok" target={newTab}></a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        Ⓒ 2025 Top Dutch Solar Racing - Software Engineering Department
        <img
          src={software_department}
          alt=""
          className="img-fluid"
          style={{ width: "15px", height: "15px", margin: "5px" }}
        ></img>
        <br></br>
        <HashLink to="/privacy-policy" className="link-to-page">
          Privacy Policy
        </HashLink>{" "}
        <br></br>
        <HashLink to="/terms-and-conditions-en" className="link-to-page">
          Terms and Conditions(EN)
        </HashLink>
        <br></br>
        <HashLink to="/terms-and-conditions-nl" className="link-to-page">
          Terms and Conditions(NL)
        </HashLink>
      </div>
    </footer>
  );
};

export default Footer;
